import React from "react";
import Layout from "../components/layout";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Cta from "../partials/Cta";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

import SvgIconBan from "../assets/icons/ban_icon.svg";
import SvgInconsistentIcon from "../assets/icons/inconsistent_icon.svg";
import SvgInneficientIcon from "../assets/icons/inefficient_icon.svg";

import SvgQualityReport from "../assets/images/quality-report.svg";
import SEO from "../components/seo";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Non-scalable",
    description:
      "Current rates of data growth means that we will rapidly run out people to review data.",
    bullets: [
      // 'Bullet1',
      // 'Bullet1',
      // 'Bullet1',
    ],
    image: (
      <SvgIconBan
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Ban icon"}
      />
    ),
  },
  {
    name: "Biased",
    description:
      "Human perspectives differ, which can create disagreements on what is ground truth.",
    bullets: [
      // 'Bullet1',
      // 'Bullet1',
      // 'Bullet1',
    ],
    image: (
      <SvgInconsistentIcon
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Inconsistent icon"}
      />
    ),
  },
  {
    name: "Redundant",
    description:
      "Humans may be reviewing data that might not be value-add to your model.",
    bullets: [
      // 'Bullet1',
      // 'Bullet1',
      // 'Bullet1',
    ],
    image: (
      <SvgInneficientIcon
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Inefficient icon"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Fast",
    description:
      "Let algorithms do the grunt work - deploy humans only when necessary",
    icon: GlobeAltIcon,
  },
  {
    name: "Simple",
    description:
      "Easy to use interface allows you to assess multiple label types.",
    icon: ScaleIcon,
  },
  {
    name: "Transparent",
    description:
      "Finally a tool that helps you discover and visualise errant labels.",
    icon: LightningBoltIcon,
  },
];

const Quality = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={
          "Automatically Find & Resolve Errors in Image and Video Annotation Data"
        }
        description={
          "Our label quality evaluation tool helps you to automatically find classification and geometric errors in your training data, ensuring that your labels are of the highest possible quality before they go into production."
        }
        displayInfoButton
      />

      {/* Three column feature section */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"Manual computer vision quality control"}
        header={
          "The problem with existing methods of annotation quality assessment"
        }
        description={
          <>
            Human supervision can be highly unreliable, biased, and redundant.
            It also adds severe constraints on throughput where high expertise
            is necessary.
          </>
        }
        useCases={useCases}
      />

      <br />
      <br />

      <ThreeColumnFeatureSection
        title={"Automated computer vision quality control"}
        header={"Augment quality assessment with automation"}
        description={
          "Encord's novel quality assessment tool helps scale your quality control processes by spotting hidden errors in your training dataset."
        }
        features={featuresGrid}
      />

      <div className="flex justify-center max-w-full rounded-md mx-auto md:max-w-none h-auto">
        <img src="https://encord.cdn.prismic.io/encord/8fff5b37-edbe-461f-bb2d-f9ea6eb62157_quality-report.svg" loading="lazy" width={1100} height={198} alt="Quality report workflow" />
      </div>

      <br />
      <br />

      <br />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={true}
        includeGrayTransition={true}
        includeTopPadding={true}
        title={"How it works"}
        header={"A new approach to annotation and segmentation accuracy"}
        description={
          "Encord has developed the first truly automated quality assessment tool - powered by micro-models. Instantly discover errors in your labels and make the most of human review."
        }
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/115cc284-22d1-42cb-b129-ec9ae8385d98_feature-encord-model-import.png?auto=compress,format"
            }
            width={500}
            alt={"Png feature model import"}
            loading="lazy"
            height={0}
            className="h-auto"
          />
        }
        imageTwo={
          <img
            src={
              "https://images.prismic.io/encord/e3b8392c-79e1-4bdf-8541-91d1ae1f6c79_quality-pred-iou-grid-in-program.png?auto=compress,format"
            }
            width={500}
            alt="Quality prediction"
            loading="lazy"
            height={0}
            className="h-auto"
          />
        }
        imageThree={
          <img
            src={
              "https://images.prismic.io/encord/57bd60f9-c5c0-4bb5-bc71-dde0cf16c8ef_review-json.png?auto=compress,format"
            }
            width={700}
            alt={"Png review json"}
            loading="lazy"
            height={0}
            className="h-auto"
          />
        }
        imageWidthOne={550}
        imageWidthTwo={500}
        imageWidthThree={500}
        headerOne={"Model validation"}
        headerTwo={"Predict label quality"}
        headerThree={"Expert review"}
        descriptionOne={
          "Import model predictions through our APIs and SDK to find errors and biases in your model."
        }
        descriptionTwo={
          "Use our automated quality control features to ensure only the best ground truth is delivered to your models."
        }
        descriptionThree={
          "Encord supports purpose-built workflows for domain experts to drive specialised quality control."
        }
      />

      <br />
      <br />
      <br />

      <Cta
        location={location}
        pageName={"Quality Page"}
        ctaText={
          "A Collaborative Software Platform for AI and ML Training Data"
        }
      />
    </Layout>
  );
};

export default Quality;

export const Head = () => (
  <SEO
    title="Annotation Quality Control for Computer Vision Training Data | Encord"
    description="Our automated data & label quality evaluation tool helps you find errors in your training data, ensuring that your labels are of the highest quality before they go into production."
  >
    <meta name="robots" content="noindex, nofollow" />
  </SEO>
);
